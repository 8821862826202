<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg
            fill="#fff"
            class="material-design-icon__svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
          >
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            >
              <title>Back</title>
            </path>
          </svg>
        </a>

        <div class="title_name w-100 text-center" style="margin-top:0px">
          <h6 class="mb-0 pt-1">Pakacrash</h6>
        </div>
      </div>

      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen webkitallowfullscreen></iframe>
    </div>

  </div>
</template>
<script>

export default {
  name: "Jetsafi",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
    };

  },
  mounted() {

    var p = this.getProfile();
    if(!p) {

      this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
      this.$router.push({ name: 'login', params: { } });
      return;

    }

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
    this.launchURL = this.gameURL();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getCasinoToken();
    },
  },
  /*
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchURL = to.params.launchURL;
        this.launchParameters = to.launchParameters;
        this.launchType = to.launchType;

        if(parseInt(this.launchType) === 2) {

          this.isLoaded = false;
          //this.getGameURL(this.gameID,this.launchParameters.start_game_url);

        } else {

          this.isLoaded = true;

        }

        console.log(this.gameName+' ==> '+this.launchURL);

      },
    },
  },
  */
  methods: {

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    gameURL: function () {

      var tokn = this.token;
      let cid = 1;

      var hostName = "https://x.jetsafi.com"

      if(tokn === undefined || tokn === null || !tokn || tokn.length === 0 || tokn.length < 5 ) {

        tokn = "demo";
      }

      var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      this.isLoaded = true;
      return hostName + "/?cid=" + cid + "&token=" + accountId + '' + tokn

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.title_name {
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  width: 100%;
  /* height: 48px; */
}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>